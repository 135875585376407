import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from 'gatsby'
import Bodytext from "../Article/Bodytext";

import {
  Section,
  SectionTitle
} from "../../components/styled";

const Who = (props) => {
  
  const {
    theme
  } = props;

  return (
    <StaticQuery query={graphql`
      query WhoQuery {
        who: markdownRemark(
          fileAbsolutePath: { regex: "//parts/who/" }
        ) {
          html
        }
      }
    `}
      render = { data => {

          const {
            who: { html: whoHTML }
          } = data;

          return (
            <Section id="who" theme={theme}>
              <SectionTitle theme={theme}>Who?</SectionTitle>
              <Bodytext html={whoHTML} theme={theme} />
            </Section>
          )
        }
      }
    />
  );
};

Who.propTypes = {
  theme: PropTypes.object.isRequired,
  data: PropTypes.shape({
    who: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired
};

export default Who;