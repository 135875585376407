export const projects = [
    {
      title: "Farfetch - Designer Clothing",
      type: "Fulltime",
      client: "Farfetch",
      description:
        "I currently work as a Senior iOS developer and team lead at Farfetch, an E-Commerce disruptive company on the world of Luxury Fashion. I work on the main iOS app, supporting and creating new features",
      techStack: "Swift, Objective-C, Ruby, fastlane",
      extra: null,
      link: "https://www.farfetch.com",
      github: {
        name: null,
        githubHref: null
      }
    },
    {
      title: "Birdy Bytes",
      type: "Startup",
      client: "Cashyt / WIT Software",
      description:
        "Cashyt is a spin-off of WIT Software that has a game called Birdy Bytes. It's a multi-game platfom that give cool rewards to its users, leveraging the sponsorship of some brands to pay for those prizes. It features phisical cards with codes that can give powers to players, currently distributed on retail, as a prize for consuming sponsor products. This was an awesome experience with game development.",
      techStack: "Objective-C, ReactiveCocoa, Realm",
      extra: null,
      link: "https://www.birdybytes.com/",
      github: {
        name: null,
        githubHref: null
      }
    },
    {
      title: "Betfair Sports Betting",
      type: "Fulltime",
      client: "Betfair",
      description:
        "When working at Blip/Betfair, I made part of a huge team that built and supported the Sportsbook app.",
      techStack: "Objective-C, Ruby, Cucumber",
      extra: null,
      link: "https://itunes.apple.com/gb/app/betfair-sports-betting/id552024276",
      github: {
        name: null,
        githubHref: null
      }
    },
    {
      title: "MEO Go, Oi Go",
      type: "Contractor",
      client: "PT Inovação / Altice Labs",
      description:
        "Smart TV on iOS devices",
      techStack: "Objective-C, CoreData",
      extra: null,
      link: "https://itunes.apple.com/us/app/meo-go/id394637480",
      github: {
        name: null,
        githubHref: null
      }
    },
    {
      title: "Localina / Localina 2",
      type: "Remote",
      client: "Local.ch",
      description:
        "Restaurant reservations booking",
      techStack: "Objective-C, ANSI C, libxml2, CoreData",
      extra: null,
      link: "https://appadvice.com/app/localina-2/664628169",
      github: {
        name: null,
        githubHref: null
      }
    },
    {
      title: "Cloogy",
      type: "Contractor",
      client: "ISA Technologies",
      description:
        "Smart metering solution for iOS devices",
      techStack: "Objective-C",
      extra: null,
      link: "https://www.cloogy.pt/en",
      github: {
        name: null,
        githubHref: null
      }
    }
  ];