export const devstack = [
    { name: "Swift 🐦" },
    { name: "Objective-C" },
    { name: "Javascript" },
    { name: "Python 🐍" },
    { name: "Ruby 💎" },
  ];
  
  export const technologies = [
    "iOS",
    "Auto Layout",
    "Realm",
    "CoreData",
    "React",
    "Bootstrap",
    "styled-components",
    "Ionic/Angular",
    "GatsbyJS"
  ];